import { mapGetters, mapActions } from 'vuex';

const baseTabMixin = {
  data: () => ({
    showUnsaved: false,
    unsavedContentTitle: 'Unsaved Changes',
    unsavedContentText: 'You have made changes to this page that are not saved. Continue and discard changes?',
    destTabComponent: '',
    destTabLink: '',
    previousTab: '',
  }),
  components: {
    BaseConfirmModal: () => import('@/components/base/BaseConfirmModal'),
  },
  computed: {
    ...mapGetters('app', ['getUnsaved']),
  },
  methods: {
    ...mapActions('app', ['setUnsaved']),
    async tabChange(newTab) {
      this.setCurrentTab(newTab);
      // We need to get the corresponding component value from the tabs array.
      const newComponent = this.tabList.filter(tab => tab.link === newTab)[0].component;
      // Stash these in case we need then in unsavedModalConfirm()
      this.destTabComponent = newComponent;
      this.destTabLink = newTab;
      // Check to see if getUnsaved === true; if it is,
      // set variable to display warning modal.
      await this.$nextTick();
      if (this.getUnsaved) {
        this.setCurrentTab(this.previousTab);
        this.showUnsaved = true;
      } else {
        // There is no unsaved content, so continue to the desired tab.
        this.setCurrentTab(newTab);
        this.$router.push({ name: newComponent });
      }
    },
    unsavedModalConfirm(confirm) {
      if (confirm) {
        // User selected Continue from modal.
        this.setCurrentTab(this.destTabLink);
        this.$router.push({ name: this.destTabComponent });
        this.setUnsaved(false);
      } else {
        // User selected Cancel
        this.setCurrentTab(this.previousTab);
      }
      this.showUnsaved = false;
    },
  },
  mounted() {
    this.previousTab = this.currentTab;
  },
};

export default baseTabMixin;
