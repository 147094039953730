<template>
  <div>
    <!-- Tabs -->
    <v-tabs
      color="secondary"
      :value="currentTab"
      @change="tabChange"
    >
      <v-tab
        v-for="(tab, i) in tabList"
        :key="i"
        :href="tab.href"
        :disabled="isDisabled(tab)"
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <BaseConfirmModal
      :value="showUnsaved"
      :title="unsavedContentTitle"
      :text="unsavedContentText"
      declineText="Cancel"
      @clicked="unsavedModalConfirm"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import baseTabMixin from '@/components/mixins/workspace/baseTabMixin';

export default {
  name: 'UserTabs',
  data: () => ({
    tabList: [
      {
        title: 'General Info',
        href: '#tab-general',
        link: 'tab-general',
        component: 'UserEdit',
      },
      {
        title: 'Enrollments',
        href: '#tab-enrollments',
        link: 'tab-enrollments',
        component: 'UserEnrollmentEdit',
      },
      {
        title: 'Alerts',
        href: '#tab-alerts',
        link: 'tab-alerts',
        component: 'UserAlertEdit',
      },
      {
        title: 'Devices',
        href: '#tab-devices',
        link: 'tab-devices',
        component: 'UserDeviceEdit',
      },
    ],
  }),
  computed: {
    ...mapGetters('app', ['getStickyTenant', 'roleAtLeastTa', 'getUnsaved']),
    ...mapGetters('users', ['getCurrent']),
    ...mapGetters('tabs', {
      currentTab: 'getSelected',
    }),
  },
  methods: {
    ...mapActions('tabs', {
      setCurrentTab: 'setSelected',
    }),
    isDisabled(item) {
      if (item.component === 'UserEdit') {
        return false;
      }
      if (item.component === 'UserDeviceEdit' && !this.roleAtLeastTa) {
        return true;
      }

      return !this.getCurrent.userId;
    },
  },
  mixins: [baseTabMixin],
};
</script>
